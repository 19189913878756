
import photo1 from './img/foto1.jpg';
import photo2 from './img/foto2.jpg';
import photo3 from './img/foto3.jpg';
import photo4 from './img/foto4.jpg';
import photo5 from './img/foto5.jpg';
import photo6 from './img/photo6.jpg';
import photo7 from './img/photo7.jpg';
import photo8 from './img/photo8.jpg';
import photo9 from './img/photo9.jpg';
import photo10 from './img/photo10.jpg';
import photo11 from './img/photo11.jpg';
import photo12 from './img/photo12.jpg';
import photo13 from './img/photo13.jpg';
import photo14 from './img/photo14.jpg';
import photo15 from './img/photo15.jpg';
import photo16 from './img/photo16.jpg';
import photo17 from './img/photo17.jpg';
import photo18 from './img/photo18.jpg';
import photo19 from './img/photo19.jpg';
import photo20 from './img/photo20.jpg';
import photo26 from './img/photo26.jpg';
import photo23 from './img/photo23.jpg';
import photo25 from './img/photo25.jpg';
import photo27 from './img/photo27.jpg';
import photo28 from './img/photo28.jpg';
import photo31 from './img/photo31.jpg';
import photo32 from './img/photo32.jpg';
import photo33 from './img/photo33.jpg';
import photo34 from './img/photo34.jpg';
import video1 from './img/video1.mp4';
import video2 from './img/video2.mp4';
import video3 from './img/video3.mp4';
import React from "react";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import RBCarousel from "react-bootstrap-carousel";
import vivenda1 from './img/vivenda1.jpg';
import vivenda2 from './img/vivenda2.jpg';
import vivenda3 from './img/vivenda3.jpg';
import vivenda4 from './img/vivenda4.jpg';
import vivenda5 from './img/vivenda5.jpg';
import vivenda6 from './img/vivenda6.jpg';
import vivenda7 from './img/vivenda7.jpg';
import vivenda8 from './img/vivenda8.jpg';
import vivenda9 from './img/vivenda9.jpg';
import vivenda10 from './img/vivenda10.jpg';
import vivenda11 from './img/vivenda11.jpg';
import vivenda12 from './img/vivenda12.jpg';
import vivenda13 from './img/vivenda13.jpg';
import vivenda14 from './img/vivenda14.jpg';
import vivenda15 from './img/vivenda15.jpg';
import vivenda16 from './img/vivenda16.jpg';
import vivenda17 from './img/vivenda17.jpg';
import vivenda18 from './img/vivenda18.jpg';
import vivenda19 from './img/vivenda19.jpg';
import vivenda20 from './img/vivenda20.jpg';
import vivenda21 from './img/vivenda21.jpg';
import vivenda22 from './img/vivendas22.jpg';
import vivenda23 from './img/vivendas23.jpg';
import vivenda24 from './img/vivendas24.jpg';
import vivenda25 from './img/vivendas25.jpg';
import vivenda26 from './img/vivendas26.jpg';
import vivenda27 from './img/vivendas27.jpg';
import vivenda28 from './img/vivendas28.jpg';
import vivenda29 from './img/vivendas29.jpg';
import vivenda30 from './img/vivendas30.jpg';
import vivenda31 from './img/vivendas31.jpg';
import vivenda32 from './img/vivendas32.jpg';
import vivenda33 from './img/vivendas33.jpg';
import vivenda34 from './img/vivendas34.jpg';
import vivenda35 from './img/vivendas35.jpg';
import vivenda36 from './img/vivendas36.jpg';
import outras1 from './img/outras1.jpg';
import outras2 from './img/outras2.jpg';
import outras3 from './img/outras3.jpg';
import outras4 from './img/outras4.jpg';
import outras5 from './img/outras5.jpg';
import outras6 from './img/outras6.jpg';
import outras7 from './img/outras7.jpg';
import outras9 from './img/outras9.jpg';
import outras10 from './img/outras10.jpg';
import outras11 from './img/outras11.jpg';
import outras13 from './img/outras13.jpg';
import outras15 from './img/outras15.jpg';
import outras16 from './img/outras16.jpg';

import { isMobile } from 'react-device-detect';
const styles = { height: 400, width: "100%" };

export default class ThirdComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true
    };
  }
  onSelect = (active, direction) => {
    // console.log(`active=${active} && direction=${direction}`);
  };
  visiableOnSelect = active => {
    //  console.log(`visiable onSelect active=${active}`);
  };
  slideNext = () => {
    this.slider.slideNext();
  };
  slidePrev = () => {
    this.slider.slidePrev();
  };
  goToSlide = () => {
    this.slider.goToSlide(4);
  };
  autoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };
  _changeIcon = () => {
    let { leftIcon, rightIcon } = this.state;
    leftIcon = leftIcon ? undefined : <span className="glyphicon glyphicon-glass" />;
    rightIcon = rightIcon ? undefined : <span className="glyphicon glyphicon-music" />;
    this.setState({ leftIcon, rightIcon });
  };
  render() {
    console.log(isMobile)
    let { leftIcon, rightIcon } = this.state;
    return (
      isMobile ?
        <div style={{ background: '#b4C8E2', height: '100%', padding: 150, width: '100%' }}>
          <h2 style={{ marginBottom: 20, marginTop: 5, color: '#F0FFFF', fontSize: 35 }} > PORTFÓLIO </h2>
          <div className="container-fluid">
            <Row>
              <Col span={30} >
                <h3 style={{ color: '#F0FFFF', fontSize: 30, marginBottom: 20 }}>Vivendas</h3>
                <RBCarousel
                  animation={true}
                  autoplay={this.state.autoplay}
                  slideshowSpeed={2000}
                  defaultActiveIndex={0}
                  leftIcon={leftIcon}
                  rightIcon={rightIcon}
                  onSelect={this.onSelect}
                  ref={r => (this.slider = r)}
                >
                   <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda22}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda23}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda24}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda25}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda26}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda27}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda28}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda29}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda30}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda31}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda32}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda33}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda34}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda35}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda36}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda1}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>

                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo23}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>

                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo25}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo26}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo27}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo28}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda2}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda3}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda4}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda5}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda6}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda7}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda8}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda9}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda10}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda11}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda12}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>

                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda13}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda14}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda15}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda16}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda17}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda18}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda19}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda20}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda21}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                </RBCarousel>
              </Col>
              <Col span={30} style={{ marginTop: 40 }}>
                <h3 style={{ color: '#F0FFFF', fontSize: 30, marginBottom: 20 }}>Outras obras</h3>
                <RBCarousel
                  animation={true}
                  autoplay={this.state.autoplay}
                  slideshowSpeed={2000}
                  defaultActiveIndex={0}
                  leftIcon={leftIcon}
                  rightIcon={rightIcon}
                  onSelect={this.onSelect}
                  ref={r => (this.slider = r)}
                >
                     <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo12}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo13}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo14}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo15}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo16}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo17}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo31}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo32}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo33}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo34}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras1}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras2}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras3}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras4}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras5}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras6}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras7}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras9}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras10}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras11}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras13}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras15}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras16}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo2}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo3}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo4}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo5}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo6}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo7}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo8}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo9}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo10}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo11}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo18}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo19}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                </RBCarousel>
              </Col>
              <Col span={30} style={{ marginTop: 40 }}>
                <h3 style={{ color: '#F0FFFF', fontSize: 30, marginBottom: 20 }}>Videos</h3>
                <RBCarousel
                  animation={false}
                  defaultActiveIndex={0}
                  leftIcon={leftIcon}
                  rightIcon={rightIcon}
                  onSelect={this.onSelect}
                  ref={r => (this.slider = r)}
                >
                  <div style={{ ...styles, backgroundColor: "aqua" }}>
                    <video className="carousel-center" controls style={{ width: "75%" }} height="250">
                      <source src={video1} type="video/mp4" />
                    </video>
                    <div className="carousel-caption">Video</div>
                  </div>
                  <div style={{ ...styles, backgroundColor: "aqua" }}>
                    <video className="carousel-center" controls style={{ width: "75%" }} height="250">
                      <source src={video2} type="video/mp4" />
                    </video>
                    <div className="carousel-caption">Video</div>
                  </div>
                  <div style={{ ...styles, backgroundColor: "aqua" }}>
                    <video className="carousel-center" controls style={{ width: "75%" }} height="250">
                      <source src={video3} type="video/mp4" />
                    </video>
                    <div className="carousel-caption">Video</div>
                  </div>
                </RBCarousel>
              </Col>
            </Row>
          </div>
        </div>
        :
        <div style={{ background: '#b4C8E2', height: '100%', padding: 200, paddingLeft: 400, width: '100%', justifyContent: "center", alignItems: "center" }}>
          <div className="container-fluid">
            <Row style={{}}>
              <Col span={10} >
                <h2 style={{ color: '#F0FFFF', fontSize: 35, marginBottom: 20 }}>PORTFÓLIO</h2>
                <RBCarousel
                  animation={true}
                  autoplay={this.state.autoplay}
                  slideshowSpeed={2000}
                  defaultActiveIndex={0}
                  leftIcon={leftIcon}
                  rightIcon={rightIcon}
                  onSelect={this.onSelect}
                  ref={r => (this.slider = r)}
                >
                       <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda22}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda23}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda24}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda25}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda26}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda27}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda28}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda29}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda30}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda31}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda32}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda33}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda34}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda35}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda36}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo12}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo13}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo14}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo15}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo16}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo17}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo31}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo32}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo33}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo34}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo20}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda1}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>

                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo23}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>

                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo25}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo26}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo27}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo28}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda2}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda3}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda4}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda5}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda6}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda7}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda8}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda9}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda10}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda11}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda12}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>

                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda13}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda14}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda15}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda16}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda17}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda18}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda19}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda20}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={vivenda21}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras1}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras2}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras3}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras4}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras5}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras6}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras7}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras9}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras10}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras11}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras13}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras15}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={outras16}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo1}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo2}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo3}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo4}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo5}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo6}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo7}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo8}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo9}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo10}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo11}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo18}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ height: 400 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={photo19}
                      alt="Responsive"
                    />
                    <div className="carousel-caption">Image</div>
                  </div>
                  <div style={{ ...styles, backgroundColor: "aqua" }}>
                    <video className="carousel-center" controls style={{ width: "75%" }} height="250">
                      <source src={video1} type="video/mp4" />
                    </video>
                    <div className="carousel-caption">Video</div>
                  </div>
                  <div style={{ ...styles, backgroundColor: "aqua" }}>
                    <video className="carousel-center" controls style={{ width: "75%" }} height="250">
                      <source src={video2} type="video/mp4" />
                    </video>
                    <div className="carousel-caption">Video</div>
                  </div>
                  <div style={{ ...styles, backgroundColor: "aqua" }}>
                    <video className="carousel-center" controls style={{ width: "75%" }} height="250">
                      <source src={video3} type="video/mp4" />
                    </video>
                    <div className="carousel-caption">Video</div>
                  </div>
                </RBCarousel>
              </Col>
            </Row>
          </div>
        </div >
    );
  }
}

/**
 *  Boostrap Component
 */
const Row = props => <div className="row">{props.children}</div>;
const Col = props => (
  <div className={`col-xs-${props.span}`} style={props.style}>
    {props.children}
  </div>
);
