import React from "react";
import imgLogo from "./img/imgLogo.png";
import { isMobile } from 'react-device-detect';

export default () => {

    return (
        !isMobile ?
            <div className="component second-component">
                <div class="container">
                    <div style={{ marginBottom: 100, marginTop: 165, padding: 6, color: '#F0FFFF', fontSize: "500%" }}> SOBRE NÓS </div>
                    <div class="row">
                        <div class="column" style={{ marginTop: -80 }}>
                            <p style={{ marginLeft: 4, color: '#F0FFFF', marginBottom: 50 }}>
                                O Grupo <strong>SAFIBRUCA</strong> tem um percurso empresarial longo, marcado por uma cultura de empreendedorismo e de inovação na procura permanente de novos horizontes. Com a sua nova restruturação, o Grupo <strong>SAFIBRUCA</strong> é hoje uma empresa com atividade centrada na construção, gestão e restauro de infraestruturas segmentada pelas áreas de Engenharia e Construção, mantendo sempre altos standards de rigor, qualidade e capacidade de execução alinhadas com as melhores práticas e numa conduta empresarial assente em princípios éticos e suportados numa visão estratégica única.
                        </p>
                            <p style={{ marginLeft: 4, color: '#F0FFFF' }}>
                                <strong>SAFIBRUCA</strong> desenvolve um vasto leque de atividades associado à concepção, construção, gestão, acabamento e manutenção de infraestruturas.
                                        Associado a um grupo de trabalho unido e responsável, com uma vasta sabedoria e experiência na área onde o mote é a satisfação dos clientes. Exemplar na afirmação, tanto na competência técnica demonstrada em cada projeto, como construindo uma reputação de excelência em áreas técnicas da construção civil e obras públicas.
                        </p>
                            <p style={{ marginLeft: 4, color: '#F0FFFF', marginTop: 20 }}>
                                A nossa empresa realiza todos os seus processos com tradições, mas sempre na vanguarga da inovação, fazendo que este tipo construção já seja possivel na nossa região.
                        </p>
                        </div>
                        <div class="column">
                            <img src={imgLogo} style={{ height: 240, marginLeft: 100, marginTop: -58 }} alt="Responsive" ></img>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="component second-component">
                <div class="container">
                    <div class="row">
                        <img src={imgLogo} style={{ height: 140, marginTop: 150, padding: 6 }} alt="Responsive" ></img>
                        <div style={{ marginBottom: 100, color: '#F0FFFF', fontSize: "500%" }}> SOBRE NÓS </div>
                    </div>

                    <div class="row">
                        <div class="row" style={{ marginTop: -80, padding: 6 }}>
                            <p style={{ marginLeft: 4, color: '#F0FFFF', marginBottom: 50, fontSize: 24 }}>
                                O Grupo <strong>SAFIBRUCA</strong> tem um percurso empresarial longo, marcado por uma cultura de empreendedorismo e de inovação na procura permanente de novos horizontes. Com a sua nova restruturação, o Grupo <strong>SAFIBRUCA</strong> é hoje uma empresa com atividade centrada na construção, gestão e restauro de infraestruturas segmentada pelas áreas de Engenharia e Construção, mantendo sempre altos standards de rigor, qualidade e capacidade de execução alinhadas com as melhores práticas e numa conduta empresarial assente em princípios éticos e suportados numa visão estratégica única.
</p>
                            <p style={{ marginLeft: 4, color: '#F0FFFF', fontSize: 24 }}>
                                <strong>SAFIBRUCA</strong> desenvolve um vasto leque de atividades associado à concepção, construção, gestão, acabamento e manutenção de infraestruturas.
        Associado a um grupo de trabalho unido e responsável, com uma vasta sabedoria e experiência na área onde o mote é a satisfação dos clientes. Exemplar na afirmação, tanto na competência técnica demonstrada em cada projeto, como construindo uma reputação de excelência em áreas técnicas da construção civil e obras públicas.
</p>
                            <p style={{ marginLeft: 4, color: '#F0FFFF', marginTop: 20, fontSize: 24 }}>
                                A nossa empresa realiza todos os seus processos com tradições, mas sempre na vanguarga da inovação, fazendo que este tipo construção já seja possivel na nossa região.
</p>
                        </div>
                    </div>


)
</div >
            </div >


    )

}
