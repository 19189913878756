import React from 'react';
import background from "./img/logo.jpg";
import fundoMobile from "./img/fundoMobile.png";
import { isMobile } from 'react-device-detect';

export default () => {
    return (
        <div className="component first-component" style={{ height: "100%", width: "100%", background: '#20334B' }} >
            <img id='background' src={isMobile ? fundoMobile : background} style={isMobile ? { height: "95%", width: "100%" } : { height: "100%", width: "100%" }} alt="Responsive" />
        </div>
    )
}
